import { createResource, For, Show, Suspense,  } from "solid-js";
import { useNavigate, useParams } from "@solidjs/router";
import { Avatar, Typography, Button,  Stack, Card, Alert, Grid,  } from "@suid/material";
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { WFStatusComponent } from '../SmallComponents/SmallWFStatusComponent';
import { WFStatusFooter } from '../SmallComponents/SmallShowWFStatusFooter';
import { SocialMediasElement, AllocationElement, DurationElement, RatesElement, ContactsElement, CompetenciesElements, TextElement, ValueElement, AddressElement } from '../SmallComponents/SmallTextElements';
import { CustomerJobMenu } from './CustomerJobMenu'
import { isSmall, isExtraSmall, isLarge, isExtraLarge } from "../../../utils/sizeUtil"
import { getText, GetTextUI } from '../SmallComponents/Translate' 
import { CustomerCVSOMEDataElementsType, CustomerJobContactsDataElementsType, CustomerJobRoleFetchType, CustomerJobHistoryFetchType } from "../../../types/customerJobDataTypes"
import { CandidateCompetenciesFetchType, CandidateCVCompetenceDataElementsType } from "../../../types/candidateCVDataTypes"

const apiUrl = import.meta.env.VITE_APP_API_URL;


const fetchJob = async (jobRoleId: string | null, jobRoleAssignmentId: string | null): Promise<CustomerJobRoleFetchType> => {
    const response = await fetch(`${apiUrl}/job/customerjobassignment/${jobRoleAssignmentId}&${jobRoleId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Jobrole response was not ok.');
    }
    const data = await response.json();
    return data;
}

const fetchJobHistory = async (jobRoleId: string | null, jobRoleAssignmentId: string | null): Promise<CustomerJobHistoryFetchType> => {
    const response = await fetch(`${apiUrl}/job/customerjobhistory/${jobRoleAssignmentId}&${jobRoleId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Jobassignment history response was not ok.');
    }
    const data = await response.json();
    return data;
}

interface tableParameters {
    fetchRoleId: string
    fetchAssignmentId: string
}

const JobAssignmentHistory = (props: tableParameters) => {
    const txtProposal = getText("default", "proposal")
    const txtContract = getText("default", "contract")
    const txtDiscussionHistory = getText("jobpage", "discussionhistory")

    const [jobHistory] = createResource<CustomerJobHistoryFetchType>(() => fetchJobHistory(props.fetchRoleId, props.fetchAssignmentId));

    return (
        <>
            <Suspense fallback={
                <Alert sx={{ px: 1, py: 1 }} severity="info">
                    <GetTextUI formName={"default"} label={"loading"} />.
                </Alert>
            }>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CardWithMinimize level={1} header={txtDiscussionHistory() as string} type="stack" defaultOpen={isExtraSmall() ? false : true}>
                            <Grid container >
                                <Grid container spacing={2}>

                                    <For each={jobHistory()?.proposals as readonly any[] | undefined}>
                                        {(item) => (
                                            <>
                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                    <CardWithMinimize iconName={"proposal"} header={txtProposal() as string} type="stack" defaultOpen={true}>
                                                        <Stack margin={2}>
                                                            <DurationElement page="default" label="duration" jobStartDate={item.startDate} jobEndDate={item.endDate} />
                                                            <br />
                                                            <AllocationElement units="hours" unitsPer="week" workRemote={item.workRemote} workOnsite={item.workOnsite} />
                                                            <RatesElement page="jobpage" label="rates" flatRate={item.flatRate} onSiteRate={item.onsiteRate} remoteRate={item.remoteRate} currency={item.currency} />
                                                        </Stack>
                                                    </CardWithMinimize>
                                                    <WFStatusFooter type="customer" actionDate={item.createdDate} userName={item.createdByUser} >{txtProposal() as string}</WFStatusFooter>
                                                </Grid>
                                            </>
                                        )}
                                    </For>
                                    <br />

                                    <For each={jobHistory()?.contracts as readonly any[] | undefined}>
                                        {(item) => (
                                            <>
                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                    <CardWithMinimize iconName={"contract"} header={txtContract() as string} type="stack" defaultOpen={true}>
                                                        <Stack margin={2}>
                                                            <DurationElement page="default" label="duration" jobStartDate={item.startDate} jobEndDate={item.endDate} />
                                                            <br />
                                                            <AllocationElement units="hours" unitsPer="week" workRemote={item.workRemote} workOnsite={item.workOnsite} />
                                                            <RatesElement page="jobpage" label="rates" flatRate={item.flatRate} onSiteRate={item.onsiteRate} remoteRate={item.remoteRate} currency={item.currency} />
                                                        </Stack>
                                                    </CardWithMinimize>
                                                    <WFStatusFooter type="customer" actionDate={item.createdDate} userName={item.createdByUser} >{txtContract() as string}</WFStatusFooter>
                                                </Grid>
                                            </>
                                        )}
                                    </For>
                                    <br />

                                </Grid>
                            </Grid>
                        </CardWithMinimize>
                    </Grid>
                </Grid >
                <Show when={!jobHistory()?.contracts || (jobHistory()?.contracts === undefined)}>
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"jobpage"} label={"jobhistorynotfound"} />
                    </Alert>
                </Show>
            </Suspense>
        </>
    );
}


const CustomerJobAssignmentViewComponent = () => {

    const params = useParams();
    const fetchRoleId = params.roleId ? params.roleId : "0";
    const fetchAssignmentId = params.assignmentId ? params.assignmentId : "0";

    const [job] = createResource<CustomerJobRoleFetchType>(() => fetchJob(fetchRoleId, fetchAssignmentId));

    const txtJobDetails = getText("jobpage", "details")

    const handleNavigateRoute = (id: string) => {
        if (id)
            navigate(id);
    }

    const navigate = useNavigate();

    return (
        <>
            <Card sx={{ margin: 1 }}>

                {/* First row of the job section show the local menubar, tags and other action buttons */}
                <CustomerJobMenu showClose={true} showActivities={false} />

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>

                    <For each={job()?.jobRole}>
                        {(item) => (
                            <>

                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>


                                        <Stack spacing={2} margin={2} direction={isExtraLarge() ? "row" : "column"}>
                                            <Stack spacing={2} direction={isExtraSmall() || isSmall() ? "column" : "row"}>
                                                <Avatar sx={{ margin: 2, display: 'flex', width: 100, height: 100 }} variant="rounded" src={item.src} />

                                                <Stack margin={2} spacing={0.5}>
                                                    <Typography variant="h4">{item.firstName} {item.lastName}</Typography>

                                                    <Typography variant="h5">{item.companyName}</Typography>
                                                    <Typography variant="h5">{item.candidateTitle}</Typography>

                                                    <Typography variant="h6">{item.candidateEducation}</Typography>
                                                    <Stack direction="row" spacing={2}>
                                                        <Button onClick={event => handleNavigateRoute("/viewcv/" + item.candidateId)} size="small" variant="outlined"><GetTextUI formName={"jobpage"} label={"viewcv"} /></Button>
                                                        <Button onClick={event => handleNavigateRoute("/customerjobroleview/" + item.jobRoleId)} size="small" variant="outlined"><GetTextUI formName={"jobpage"} label={"viewjobrole"} /></Button>
                                                    </Stack>
                                                </Stack>

                                            </Stack>

                                            <Card  >
                                                <WFStatusComponent type={"candidate"} />
                                            </Card>

                                        </Stack>

                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <CardWithMinimize level={1} header={txtJobDetails() as string} type="stack" defaultOpen={isExtraSmall() ? false : true}>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Stack spacing={1} margin={2}>

                                                    <TextElement page="jobpage" label="jobdescription" data={item.jobDescription} />

                                                    <SocialMediasElement page="jobpage" label="social" SOMEs={job()?.jobSOMEs as CustomerCVSOMEDataElementsType} />

                                                    <ContactsElement page="jobpage" label="contacts" contacts={job()?.contacts as CustomerJobContactsDataElementsType} />

                                                    {/* Project location */}
                                                    <AddressElement page="contact" label="address" data={""} country={item?.jobCountry} postalCode={item?.jobPostalCode} city={item?.jobCity} street={item?.jobStreet} />

                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                                                <Stack direction="column" spacing={1}>
                                                    <TextElement page="jobpage" label="jobrole" data={item.jobRoleName} />
                                                    <TextElement page="jobpage" label="jobroledescription" data={item.jobRoleDescription} />
                                                    <TextElement skip={true} page="jobpage" label="jobroleexpectation" data={item.jobRoleExpectation} />
                                                    <TextElement skip={true} page="jobpage" label="jobroleadditionalinformation" data={item.jobRoleAdditionalInformation} />
                                                    <br />

                                                    <DurationElement page="default" label="duration" jobStartDate={item.jobStartDate} jobEndDate={item.jobEndDate} />


                                                    <br />
                                                    <AllocationElement units="hours" unitsPer="week" workRemote={item.workRemote} workOnsite={item.workOnsite} />


                                                    <RatesElement page="default" label="budget" flatRate={item.maxFlatRate} onSiteRate={item.maxOnSiteRate} remoteRate={item.maxRemoteRate} currency={item.currency} />

                                                </Stack>
                                            </Grid>

                                        </CardWithMinimize>
                                    </Grid>
                                </Grid>

                                <CompetenciesElements showAddButton={false} page={"jobpage"} label={"customercompetenciesexplainedassignment"} options={job() as CandidateCompetenciesFetchType} />

                                <JobAssignmentHistory fetchRoleId={fetchRoleId} fetchAssignmentId={fetchAssignmentId} />
                            </>
                        )}
                    </For>

                    <Show when={!job()?.jobRole || (job()?.jobRole?.length === 0)}>
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            <GetTextUI formName={"jobpage"} label={"jobrolenotfound"} />
                        </Alert>
                    </Show>

                </Suspense>

            </Card>
            <br />
            <br />
        </>
    );
}

export { CustomerJobAssignmentViewComponent }  