import { PageTemplate } from './PageTemplate'
import { CustomerJobAssignmentViewComponent } from '../components/Customer/CustomerJobAssignmentViewComponent'

const ShowCustomerJobAssignmentViewPage = () => { 
    return (
        
        <PageTemplate
            type="customer" headerPage="jobpage" headerElement="jobassignment"
            leftPaneComponent={null} mainComponent={ CustomerJobAssignmentViewComponent()} buttonComponent={null} >
         <></>
        </PageTemplate>
        
    );
}
export { ShowCustomerJobAssignmentViewPage }